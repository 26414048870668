import { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../env';

export default function AdminCreateCategory() {
    const token = localStorage.getItem("authToken");
    const [data, setData] = useState({
        name: '',
        arrangement: '1',
        img: '',
    })

    const buttonDisabled = () => {
        let valid = true;
        if (!data?.name || !data?.img) {
            valid = false;
        }
        return valid;
    }

    const handleChangeValue = (field, value) => {
        setData({ ...data, [field]: value });
    }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('arrangement', data.arrangement);
        formData.append('img', data.img);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            },
        };
        axios.post(`${baseUrl}/Category/createCategory`, formData, config)
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div className="max-w-[600px] mx-auto border border-gray-300 mt-10">
            <div className='flex flex-col gap-4 p-10'>
                <div className='flex flex-col pt-4'>
                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900">Kategori Adı</label>
                    <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required
                        type="text"
                        id="name"
                        onChange={(e) => handleChangeValue("name", e.target.value)}
                    />
                </div>
                <div className='flex flex-col pt-4'>
                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900">Sıralama</label>
                    <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required
                        type="number"
                        id="arrangement"
                        value={data?.arrangement}
                        onChange={(e) => handleChangeValue("arrangement", e.target.value)}
                    />
                </div>
                <div className='flex flex-col pt-4'>
                    <label for="file" className="block mb-2 text-sm font-medium text-gray-900 ">Kategori Fotoğrafı</label>
                    <input
                        onChange={(e) => handleChangeValue("img", e.target.files[0])}
                        type="file" id="file" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5" required />
                </div>
                <div className='flex justify-end gap-2'>
                    <button disabled={!buttonDisabled()} onClick={handleSubmit} className="mt-6 bg-gray-900 hover:bg-gray-600 items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Ekle</button>                   
                </div>
            </div>
        </div>
    )
}