import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { baseUrl } from '../env'
import { Link } from 'react-router-dom'
import ClipLoader from "react-spinners/ClipLoader";

function Products() {
  let { name } = useParams();
  const [products, setProducts] = useState();

  useEffect(() => {
    axios.get(`${baseUrl}/Product/getProductsByCategoryName?categoryName=${name}`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [])

  if (products) {
    return (
      <section class="max-w-screen-md mx-auto" style={{ fontFamily: 'Roboto' }}>
        <div class="grid grid-cols-2 gap-1 mt-6">
          {products?.map(product =>
            <div class="bg-white border border-gray-200 p-1 pt-4 rounded-lg relative min-h-[300px] max-h-[400px]">
              <span class="bg-red-100 border border-red-300 rounded-full text-primary text-sm poppins px-4 py-1 inline-block mb-4 "><Link to={{ pathname: `/${product.category?.name}` }}>{product.category?.name}</Link></span>
              <img class="h-[180px] w-full mx-auto " src={`${baseUrl}/${product?.img}`} />
              <div class="flex flex-col items-center pt-3">
                <h1 class="text-gray-900 text-2xl font-medium">{product.name}</h1>
                <h2 class="text-gray-900 poppins text-2xl font-medium pt-3">{product.price} TL</h2>
              </div>
            </div>
          )}
        </div>
      </section>
    )
  }
  else {
    return (
      <div class="flex h-[80vh]">
        <div class="m-auto">
          <ClipLoader className='fixed' />
        </div>
      </div>
    )
  }
}

export default Products;