import './App.css';
import Menu from './layout/Menu';
import Header from './layout/Header';
import { Route, Routes, Navigate, Router } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Categories from './Foods/Categories.js';
import SearchBar from './layout/SearchBar.js';
import Products from './Foods/Products.js';
import SearchProducts from './Foods/SearchProducts.js';
import Admin from './Admin/Admin.js';
import AdminHome from './Admin/AdminHome.js';
import AdminCategories from './Admin/Categories/AdminCategories.js';
import AdminCreateCategory from './Admin/Categories/AdminCreateCategory.js'
import AdminProducts from './Admin/Products/AdminProducts.js'
import AdminCreateProduct from './Admin/Products/AdminCreateProduct.js';
import Login from './Login.js';

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('authenticateResult') == "true") {
      setIsAuthorized(true);
    }
    else {
      setIsAuthorized(false);
    }
  }, [])


  return (
    <>
      {!isAuthorized ?
        <div className="App">
          <Header />
          <SearchBar />
          <Routes>
            <Route path="/" element={<Categories />} />
            <Route path="/:name" element={<Products />} />
            <Route path="/search/:searchData" element={<SearchProducts />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
        :
        <div className="App">
          <Routes>
            <Route path="*" element={<Navigate replace to="/authorized" />} />
            <Route path="/authorized" element={<Admin />}>
              <Route path="" element={<AdminHome />} />
              <Route path="categories" element={<AdminCategories />} />
              <Route path="create-category" element={<AdminCreateCategory />} />
              <Route path="products" element={<AdminProducts />} />
              <Route path="create-product" element={<AdminCreateProduct />} />
            </Route>
          </Routes>
        </div>
      }

    </>

  );
}

export default App;
